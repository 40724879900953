import request from '@/router/axios'

export function getPage(query) {
  return request({
    // url: 'api/material/page',
    url: 'admin/sys-file/page',
    method: 'get',
    params: query
  }).then((res) => { return res.data })
}

export function addObj(obj) {
  return request({
    url: 'api/material',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: 'api/material/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    // url: 'api/material/' + id,
    url: 'admin//sys-file/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: 'api/material',
    method: 'put',
    data: obj
  })
}

export function uploadMaterialImage(obj){
  let upLoadconfig = {
    headers: {
    'Content-Type': 'multipart/form-data'
    }
  }        
  return request({
    method: 'post',
    url: 'admin/sys-file/upload',
    data: obj,
    upLoadconfig,
  }).then((res) => {
      return res.data;
  })
}